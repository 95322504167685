<!-- footer start -->
<footer id="gen-footer">
    <div class="gen-footer-style-1">
        <div [ngClass]="isCustomView ?'pt-2' :'gen-footer-top'">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                        <div class="widget">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h3 class="footer-title pr-3" i18n="@@footer.facilityName">Hospital Facility Name:
                                    </h3>
                                    <IMG width="155" [src]="facilityLogo" BORDER="0" ALT="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-8 col-md-7 col-sm-6">
                        <div class="row pb-3" *ngIf="!isCustomView">
                            <div class="col-sm-12">
                                <h5 class="footer-title" i18n="@@footer.useful">Useful Links</h5>
                                <ul class="social-link">
                                    <li *ngIf="!isFreeFlag" class="menu-item"><a [routerLink]="['/home/rental']"
                                            style="font-size:13px;" i18n="@@footer.order">Order HopiFlix</a></li>
                                    <li *ngIf="!isFreeFlag" class="menu-item"><a [routerLink]="['/home/account']"
                                            style="font-size:13px;" i18n="@@footer.my-account">My Account</a></li>
                                    <li class="menu-item"><a [routerLink]="['/home/call-centre']"
                                            style="font-size:13px;" i18n="@@footer.call-centre">Call Centre
                                            1-866-MEDITEL&nbsp;(633-4835)</a></li>
                                    <li class="menu-item"><a [routerLink]="['/home/contact']" style="font-size:13px;"
                                            i18n="@@footer.contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-sm-12">
                                <h5 class="footer-title" i18n="@@footer.other-resources">Other Resources</h5>
                                <ul class="social-link">
                                    <li class="menu-item"><a [routerLink]="['/home/about']" style="font-size:13px;"
                                            i18n="@@footer.about">About</a></li>
                                    <li class="menu-item"><a [routerLink]="['/home/terms-of-use']"
                                            style="font-size:13px;" i18n="@@footer.terms">Terms of Use</a></li>
                                    <li class="menu-item"><a [routerLink]="['/home/privacy-policy']"
                                            style="font-size:13px;" i18n="@@footer.policy">Privacy Policy </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-sm-12">
                                <h5 class="footer-title" i18n="@@footer.about">About</h5>
                                <div class="social-link">
                                    <IMG SRC="./assets/images/Hopitel_new _logo_2023.png" WIDTH="131" HEIGHT="35"
                                        BORDER="0" ALT="" class="pr-4">

                                    <IMG class="ml-2" *ngIf="!isCustomView"
                                        [src]="hasEnCulture?'./assets/images/logo-1.png':'./assets/images/hopiflix-french-logo.png'"
                                        WIDTH="135" HEIGHT="35" BORDER="0" ALT="" class="pr-4">
                                    <!-- <IMG [src]="hasEnCulture?'./assets/images/Hopiface_logo_En.png':'./assets/images/Hopiface_logo_Fr.png'"
                                        WIDTH="150" HEIGHT="35" BORDER="0" ALT="" class="pr-4"> -->
                                    <IMG *ngIf="!isCustomView" [src]="hasEnCulture?'./assets/images/mediastudio_logo_skyblue_en.png':'./assets/images/mediastudio_logo_skyblue_fr.png'"
                                        WIDTH="105" HEIGHT="35" BORDER="0" ALT="" class="pr-4">

                                </div>
                                <p style="font-size:12px;line-height:18px;" i18n="@@footer.paragraph"
                                    *ngIf="!isCustomView">
                                    <br>
                                    <b>Hôpitel,</b> a healthcare media company serving patients and hospitals in Canada
                                    for over 40
                                    years introduces <b>HôpiFlix,</b> the first <b>BYOD</b> streaming Infotainment and
                                    Infohealth platform
                                    for patients, patient family members and hospital personnel.
                                    <br><br>
                                    <b>HôpiFlix</b> offers curated Infotainment content including IPTV, On-Demand
                                    Movies, Music,
                                    Radio, Audiobooks, Relaxation Videos, Kids Corner and more, all accessible for
                                    patients on
                                    their personal media devices.
                                    <br><br>
                                    <b>HôpiFlix</b> also integrates other high value Infohealth applications such as
                                    PatientLogix Anywhere Access, which
                                    incorporate
                                    prescribed Health Education media content that include videos and documents along
                                    with
                                    follow-up questionnaires and prescribed patient Satisfaction Surveys accessible in
                                    hospitals, clinics, and the patient home/workplace that keep patients well informed
                                    on their
                                    personal healthcare throughout their healthcare journey.
                                    <br><br>
                                    Hospital Information,
                                    Meal Menus, an Interactive Clinician/Patient Whiteboard, Concierge Services, Nurse
                                    Call,
                                    Room Controls and more, all of which are also accessible for patients on their
                                    personal
                                    media devices.
                                    <br><br>
                                    Order <b>HôpiFlix</b> by clicking on the <b>“RENT SERVICES”</b> button or call
                                    1-866-MEDITEL (633
                                    4835) from 9:00 am to 10:00 pm, 7 days per week, and sit back, relax, and enjoy
                                    <b>HôpiFlix’s
                                        BYOD</b> streaming services today!
                                </p>
                                <p style="font-size:12px;line-height:18px;" i18n="@@footer.hopitel-healthcare"
                                    *ngIf="isCustomView">
                                    <br>
                                    <b>Hôpitel,</b> a healthcare media company serving patients and hospitals in Canada
                                    for over 40
                                    years introduces <b>HôpiFlix,</b><br> the first <b>BYOD</b> streaming Infotainment and
                                    Infohealth platform
                                    for patients, patient family members and hospital personnel.
                                </p>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <img [src]="hasEnCulture?'../../../../assets/images/logo-1.png' : '../../../../assets/images/hopiflix-french-logo.png'"
                                            class="img-fluid logo" alt="gen-footer-logo">
                                        <ul class="social-link" style="padding-left:25px;">
                                            <li><a href="https://www.facebook.com/HopitelCanada/" class="facebook"><i
                                                        class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.instagram.com/hopitelcanada/" class="instagram"><i
                                                        class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.youtube.com/channel/UC2xCyLRhMGn5qEuN1STAXyQ"
                                                    class="linkedin"><i class="fab fa-youtube"></i></a></li>
                                            <li><a href="https://twitter.com/hopitel" class="twitter"> <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style="background-color: white;" height="1em"
                                                        viewBox="0 0 512 512">
                                                        <path
                                                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                                    </svg></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gen-copyright-footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 align-self-center">
                        <span class="gen-copyright" i18n="@@footer.reserved">
                            CONFIDENTIAL © Hôpitel<sup>&reg;</sup> Inc., Hôpiflix<sup>&reg;</sup>,
                            Patientlogix<sup>&reg;</sup>, All
                            Rights Reserved {{year}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row py-3" style="font-size: 11px;color:#ffffff ;">
                <div class="col-sm-6 text-left text-position"><I i18n="@@footer.hopitel">Powered
                        by Hopitel<sup>&reg;</sup> Inc. &
                        CronosEdge&trade; v6.2</I></div>
                <div class="col-sm-6 text-right text-position"><I i18n="@@footer.designed">Designed, Built
                        and
                        Supported in
                        Canada&nbsp;&nbsp;</I><IMG SRC="../../../../assets/images/canada-leaf.png" WIDTH="25"
                        HEIGHT="27" BORDER="0" ALT="">
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer End -->