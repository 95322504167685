import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NbDialogService } from '@nebular/theme';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { RadioChannel } from 'src/app/@core/data/radioChannel';
import { RadioService } from 'src/app/@core/services/radio.service';
import { CommonService } from 'src/app/@core/utils';
import { ServiceNotAccessComponent } from 'src/app/pages/service-not-access/service-not-access.component';

@Component({
  selector: 'ngx-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss']
})
export class RadioCardComponent implements OnInit {
  @Input() radioChannels: Array<RadioChannel> = [];
  @Input() isForCarousel: boolean = false;
  @Input() fromPlayList: boolean = false;
  defaultImagePath: string;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  };
  patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;
  userChangeSubscription: Subscription;
  serviceNotAccessPopup: any;

  constructor(private router: Router, public sanitizer: DomSanitizer, private commonService: CommonService,
    private dialogService: NbDialogService, private authService: MsalService, private radioService: RadioService) {
    this.defaultImagePath = this.commonService.defaultImage();
    this.patientPlaylistItem.forRadio = true;
    // rebindPlayList
    this.userChangeSubscription = this.commonService.rebindPlayList.subscribe((res: any) => {
      if (res) {
        const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
        this.radioChannels = this.radioService.setPatientPlaylist(this.radioChannels, ceFacilityId);
        this.commonService.rebindPlayList.next(false);
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.serviceNotAccessPopup?.close();
  }

  checkUserLogInForRadioPlayer(webUrl: any): void {
    const account = this.authService.instance.getAllAccounts()[0];
    if (account || this.commonService.isFreeFacilityFlag)
      this.openRadioPlayer(webUrl);
    else {
      this.commonService.setLSKey('purl', { key: 'RADIO_TRAILER', id: webUrl });
      this.authService.loginRedirect();
    }

  }

  openRadioPlayer(webUrl: string) {
    if (webUrl === "maillist")
      this.router.navigate([webUrl]);
    else if (this.commonService.isFreeFacilityFlag)
      window.open(webUrl, '_blank');
    else {
      this.commonService.checkRentedService("PREMIUM_BYOD").subscribe(data => {
        if (data.body) {
          window.open(webUrl, '_blank');
        }
        else
          this.servceNotAccess();
      });
    }
  }


  servceNotAccess() {
    this.serviceNotAccessPopup = this.dialogService.open(ServiceNotAccessComponent, { hasBackdrop: true, closeOnBackdropClick: false, autoFocus: false });
  }

  getUpdatedPlayList(video: any) {
    if (this.fromPlayList) {
      const index = this.radioChannels.findIndex((x: any) => x.id == video.id);
      if (index >= 0)
        this.radioChannels.splice(index, 1);
    }
  }
} 