import { DOCUMENT } from '@angular/common';
import { Component, DoCheck, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { elementAt } from 'rxjs/operators';
import { FacilityTicker } from 'src/app/@core/data/facilityTicker';
import { FacilityService } from 'src/app/@core/services/facility.service';
import { HospitalInfoService } from 'src/app/@core/services/hospital-info.service';
import { PatientPlaylistService } from 'src/app/@core/services/patient-playlist.service';
import { PrescriptionManagerService } from 'src/app/@core/services/prescription-manager.service';
import { RentalService } from 'src/app/@core/services/rental.service';
import { CommonService } from 'src/app/@core/utils';
import { MySeviceComponent } from 'src/app/pages/shared/my-sevice/my-sevice.component';
import { NotificationsComponent } from 'src/app/pages/shared/notifications/notifications.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() facilities: Array<any> = [];
  @Input() selectedFacility: any = null;
  @Input() hospitalInfos: any;
  @Input() facilityTicker: FacilityTicker = {} as FacilityTicker;
  @Input() isFreeFlag: any;
  @Input() facilityLogo: any;
  @Input() facilityViewOption: any;
  @Input() isHopiflixPrescription: any;
  @Input() isHopiflixCommunication: any;
  hasEnCulture: boolean;
  userName: string = '';
  dropdownShow: boolean = false;
  rebindStatsSubscription: Subscription;
  bindUserDetailSubscription: Subscription;
  totalUnseenPrescription: any = 0;
  language: string = '';
  showInfoHealth: boolean = false;
  showPatientInfo: boolean = false;
  showCommunications: boolean = false;
  showRooms: boolean = false;
  showHospital: boolean = false;
  demoModeEnabled: boolean;
  notificationModal: any;
  showFacilityDropDown: boolean = false;
  showInfotainmentDropdown: boolean = false;
  showLanguageDropdown: boolean = false;
  @Output()
  changeFacility: EventEmitter<any> = new EventEmitter<any>();
  showDropdown: boolean = false;
  isTileview: boolean = false;
  myServiceModal: any;
  isCustomview:boolean=false;
  
  constructor(private authService: MsalService, private commonService: CommonService,
    @Inject(DOCUMENT) private readonly document: any,
    private prescriptionManagerService: PrescriptionManagerService,
    private router: Router, private nbDialogService: NbDialogService, private rentalService: RentalService,
    private patientPlaylistService: PatientPlaylistService, private hopitalInfoService: HospitalInfoService, private cookieService: CookieService) {
    this.demoModeEnabled = environment.demoModeEnabled;
    this.setLanguageText(this.commonService.getLanguage());
    this.hasEnCulture = this.commonService.getLanguage() === 'en';
    this.isTileview = this.commonService.getUserView();
    this.isCustomview = this.commonService.isCustomView;
    this.getUser();
    //rebind prescriptionCount
    this.rebindStatsSubscription = this.commonService.rebindStats.subscribe((res: any) => {
      if (res) {
        const counts = this.commonService.CountPrescriptions();
        this.totalUnseenPrescription = counts.totalUnseenPrescription;
      }
    });

    //showuser
    this.bindUserDetailSubscription = this.commonService.BindUserDetail.subscribe((res: any) => {
      if (res && this.userName == '')
        this.getUser()
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.rebindStatsSubscription.unsubscribe();
    this.bindUserDetailSubscription.unsubscribe();
    this.myServiceModal?.close();
    this.notificationModal?.close();
  }

  ngDoCheck() {
    const dropdownShow = this.showInfotainmentDropdown || this.showFacilityDropDown || this.dropdownShow || this.showLanguageDropdown || this.showCommunications || this.showInfoHealth || this.showPatientInfo || this.showHospital || this.showRooms;
    if (dropdownShow != this.showDropdown) {
      this.showDropdown = dropdownShow;
      this.commonService.isOpenHeaderDropdown.next(dropdownShow);
    }

  }

  logout() {
    this.commonService.clearLocalStorage();
    this.authService.logout();
  }

  openloginRedirect() {
    this.authService.loginRedirect()
  }

  onChangeFacility(facility: any) {
    this.showFacilityDropDown = !this.showFacilityDropDown
    if (this.selectedFacility.facilityCode != facility?.facilityCode) {
      this.selectedFacility = facility;
      this.selectedFacility.name = (!this.hasEnCulture && facility?.fullNameFr != null) ? facility?.fullNameFr : facility?.fullName;
      this.commonService.setLSKey("facilityCode", facility?.facilityCode);
      this.commonService.setLSKey("facilityTitle", this.selectedFacility.name);
      this.commonService.setLSKey("ceFacilityId", facility?.id);
      this.getHospitalInfo(facility);
    }
  }

  switchLanguage(code: string) {
    this.showLanguageDropdown = !this.showLanguageDropdown;
    const appCode = this.commonService.getLanguage();
    if (appCode !== code) {
      var path = this.document.location.pathname;
      var baseUri = this.document.location.origin;
      var redirectToUrl = ''
      if (code == 'fr') {
        redirectToUrl = baseUri + '/fr' + path;
        this.commonService.changeLanguage('fr');
      }
      else {
        const stringToGoIntoTheRegex = `/fr`;
        const replace_base_href = new RegExp(stringToGoIntoTheRegex, "gi");
        redirectToUrl = baseUri + path.replace(replace_base_href, '');
        this.commonService.changeLanguage('en');
      }
      if (redirectToUrl !== '')
        this.document.location.href = redirectToUrl;
    }
  }

  @HostListener('document:click', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    const elm: any = event.target;
    let accessUserMenu = elm.classList.contains("fa-user")
    if (!accessUserMenu && this.dropdownShow)
      this.dropdownShow = false
  }

  showDropDown(show: boolean) {
    this.dropdownShow = show ? false : true
  }

  gotoNotification() {
    this.showPatientInfo = false;
    if (this.userName != '') {
      this.notificationModal = this.nbDialogService.open(NotificationsComponent, { hasBackdrop: true, closeOnBackdropClick: false, autoFocus: false });
    }
    else
      this.router.navigate(['/home/landing']);
  }

  setLanguageText(appCode: string): void {
    this.language = appCode === 'fr' ? 'Français' : 'English';
  }

  openServicePopup() {
    this.myServiceModal = this.nbDialogService.open(MySeviceComponent, { hasBackdrop: true, closeOnBackdropClick: false, autoFocus: false });
  };

  getPatientPlaylist() {
    this.patientPlaylistService.getPatientPlaylist().subscribe(res => {
      if (res != null && res != undefined)
        this.commonService.rebindPlayList.next(true);
    });
  }

  showHospitalDropdown() {
    this.router.navigate(['/home/hospital-Info'])
  }

  openViewerPopUp(url: any): void {
    this.closeHeaderDropDown();
    window.open(url, 'popup', 'width=600,height=400');
  }

  getHospitalInfo(facility: any) {
    this.hospitalInfos = [];
    if (facility?.id > 0) {
      this.hopitalInfoService.getHospitalInfo(facility.id, true).subscribe(result => {
        if (result)
          this.hospitalInfos = result;
        this.changeFacility.emit(facility);
        this.commonService.setLSKey('hopitalInfos', this.hospitalInfos);
        this.commonService.facilityChanges.next(true);
      })
      return;
    }
    this.changeFacility.emit(facility);
    this.commonService.setLSKey('hopitalInfos', this.hospitalInfos);
    this.commonService.facilityChanges.next(true);
  }

  getUser() {
    const account = this.authService.instance.getAllAccounts()[0];
    if (account) {
      this.userName = account.username;
      this.getPatientPlaylist();
      this.prescriptionManagerService.getPatientPrescriptions().toPromise();
    }
  }

  closeHeaderDropDown() {
    this.showInfotainmentDropdown = false;
    this.showCommunications = false;
    this.showInfoHealth = false;
    this.showPatientInfo = false;
    this.showRooms = false;
    this.showHospital = false;
  }

  changeUserView() {
    this.isTileview = !this.isTileview;
    this.commonService.setLSKey('isTileview', this.isTileview);
    const url = this.isTileview ? '/home/tileview/landing' : '/home/landing'
    let redirectToUrl = this.document.location.origin;
    if (!this.hasEnCulture)
      redirectToUrl = `${redirectToUrl}/fr/`;
    redirectToUrl = `${redirectToUrl}${url}`
    this.document.location.href = redirectToUrl;
  }

  redirectToIptv() {
    this.commonService.setLSKey('pathnameUrl', window.location.pathname);
    this.router.navigate(['/home/infotainment/iptv']);
  }
}