import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HospitalInfo, HospitalInfoData } from '../data/hospital-Info';
import { ApiUrlService, CommonService } from '../utils';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HospitalInfoService implements HospitalInfoData {
  isPublished: boolean = true;

  constructor(private urlServices: ApiUrlService, @Inject(DOCUMENT) private readonly document: any, private httpClient: HttpClient, private commonService: CommonService) { }

  getAllHospitalInfo(ceFacilityId: number, folderName: string = "/", pageIndex: number, pageSize: number): Observable<Array<HospitalInfo>> {
    const langCode = this.commonService.getLanguage();
    const url = `${this.urlServices.hospitalInfoUrl}/facilities/${ceFacilityId}/facility-documents-filtered?isPublished=${this.isPublished}&folderName=${folderName}&pageSize=${pageSize}&pageIndex=${pageIndex}&languageContext=${langCode}`;
    return this.httpClient.get<Array<HospitalInfo>>(url)
      .pipe(
        map((returnData: Array<HospitalInfo>) => {
          return returnData;
        }),
        catchError(this.handleError<Array<HospitalInfo>>('getAllHospitalInfo', []))
      );
  }

  getWebPageById(id: number): Observable<any> {
    const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
    const url = `${this.urlServices.hospitalInfoUrl}/facilities/${ceFacilityId}/facility-documents/${id}`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getWebPageById', []))
      );
  }

  getHospitalInfo(ceFacilityId: any, forHospital: boolean = false): Observable<any> {
    const url = `${this.urlServices.hospitalUrl}/${ceFacilityId}/facility-parameters-with-value`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          const isFreeFacility = returnData?.find((hopitalInfo: any) => hopitalInfo.name == 'Hopiflix_Free')?.facilityParameterConfiguration.value;
          this.commonService.isFreeFacilityFlag = isFreeFacility?.toLowerCase() == "true" ? true : false;
          this.commonService.facilityCurrentView = returnData?.find((hopitalInfo: any) => hopitalInfo.name == 'Hopiflix_ViewOption')?.facilityParameterConfiguration.value;
          const facilityLanguage = returnData?.find((hopitalInfo: any) => hopitalInfo.name == 'Hopiflix_DefaultCulture')?.facilityParameterConfiguration.value;
          this.commonService.facilityLanguage = facilityLanguage?.toLowerCase() == "fr" ? 'fr' : 'en';
          const hopiflixPrescription = returnData?.find((hopitalInfo: any) => hopitalInfo.name == "Hopiflix_Prescription")?.facilityParameterConfiguration.value;
          this.commonService.isHopiflixPrescription = hopiflixPrescription?.toString().toLowerCase() == "false" ? false : true;
          const hopiflixCommunication = returnData?.find((hopitalInfo: any) => hopitalInfo.name == "Hopiflix_Communication")?.facilityParameterConfiguration.value;
          this.commonService.isHopiflixCommunication = hopiflixCommunication?.toString().toLowerCase() == "false" ? false : true;
          if (forHospital) {
            const hasEnCulture = this.commonService.getLanguage() === 'fr';
            let hopitalInfo: Array<any> = [];
            let hopital = returnData?.find((hopitalInfo: any) => hopitalInfo.name == 'HospitalLinks');
            if (hopital != null && hopital != undefined) {
              hopital = hopital?.facilityParameterConfiguration?.value;
              hopitalInfo = JSON.parse(hopital);
              hopitalInfo.map(x => {
                x.title = (hasEnCulture && x.TitleFr != null) ? x.TitleFr : x.Title;
                x.description = (hasEnCulture && x.DescriptionFr != null) ? x.DescriptionFr : x.Description;
                x.thumbnailUrl = (hasEnCulture && x.ThumbnailFrUrl != null) ? x.ThumbnailFrUrl : x.ThumbnailUrl;
              });
              const priorityGroup = hopitalInfo.filter((x: any) => x.DisplayPriority != null).sort((a: any, b: any) => ((a.DisplayPriority) > (b.DisplayPriority)) ? 1 : -1);
              const nonPriorityGroup = hopitalInfo.filter((x: any) => x.DisplayPriority == null).sort((a: any, b: any) => ((a.DisplayPriority) > (b.DisplayPriority)) ? 1 : -1);
              hopitalInfo = priorityGroup.concat(nonPriorityGroup);
            }
            return hopitalInfo;
          }
          return returnData;
        }),
        catchError(this.handleError<any>('getHospitalInfo', []))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
